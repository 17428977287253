import Fetch from './fetch';

export const getDocumentTypes = () => Fetch.get("/orders:document-types").then((x) => x.data);

export const orderByDescription = (data) => {
  return data.sort(function (a, b) {
    if (a.DocumentTypeEnumDescription > b.DocumentTypeEnumDescription) return 1;
    if (a.DocumentTypeEnumDescription < b.DocumentTypeEnumDescription) return -1;

    return 0;
  });
}

export const getDocumentTypesByBusinessUnityCountry = async (id) => {

  try {
    const businessUnitCountry = await Fetch.get(`/unidadeNegocio/${id}`).then(({ data }) => data.PaisEnumValue)
    const allDocumentTypes = JSON.parse(localStorage.getItem('DocumentTypes'));
    const documentTypesByCountry = allDocumentTypes.filter((item) => item.Country === businessUnitCountry);
    const documentTypes = orderByDescription(documentTypesByCountry);
    return documentTypes;

  } catch (error) {
    console.error(error)
  }
}


