import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import InformAdvanceDelayOrder from 'components/inform-advance-delay-order'
import SnackBar from 'material-ui/Snackbar'
import DangerDialog from '../danger-dialog'
import SessionUser from 'utils/user-storage'
import Loading from 'components/center-loading'
import intl from 'react-intl-universal'

import {
    searchCardDetail,
    changeRequest,
    cancelBalance,
    cancelOrder
} from './orders.service'

import Orders from './orders'
import PendingVolum from './pendingVolum'
import { formatErrorMessage } from 'utils/handle-error';

const TipoPedidoTransferencia = 2;

class OrdersVolum extends Component {
    static propTypes = {
        match: PropTypes.shape({
            params: PropTypes.shape({
                familyId: PropTypes.string,
                itemId: PropTypes.string,
                typeNeed: PropTypes.string
            })
        }),
        history: PropTypes.object
    }

    constructor(props) {
        super(props)

        this.state = {
            estoqueDetalhe: null,
            chartData: {
                Details: [],
                guides: {},
            },
            lastOrderOpen: {
                index: {},
                tableListBodyElement: {},
            },
            pedido: {},
            showFeedback: false,
            messageFeedback: '',
            requestCount: 0,
            idFamiliaRotuladaCentro: parseInt(props.match.params.familyId),
            date: props.match.params.date,
            datetype: parseInt(props.match.params.datetype),
            idPedidoCancelamento: null,
            showCancelConfirm: false,
            showCompositionOrders: false,
            showFormOrder: false,
            justificationMessages: [],
            searchTimeLine: false,
            idJustificativaCancelamento: null,
            indexOrigem: null
        }
    }

    async componentDidMount() {
        this.loadCompletePage();
    }

    async loadCompletePage() {
        this.setState({
            searchTimeLine: true,
        });

        await this.getOrders();
    }

    async getOrders() {
        const { idFamiliaRotuladaCentro, date, datetype } = this.state

        this.handleFetch()

        const estoqueDetalhe = await this.loadCardDetail(idFamiliaRotuladaCentro, date, datetype)
        this.setState({
            estoqueDetalhe,
        });

        this.searchMessages()
        this.handleFetch(false)
    }

    async loadCardDetail(idFamiliaRotuladaCentro, date, datetype) {
        try {
            const data = await searchCardDetail(idFamiliaRotuladaCentro, date, datetype)
            return data
        } catch (err) {
            this.showFeedback(intl.get('orders.feedback.errorBringingOrders'))
        }
    }

    async saveOrder(item) {
        const alteracaoPedido = {
            IdPedido: item.Id,
            ResponsavelEntrega: item.ResponsavelEntrega
        }

        this.handleFetch()

        try {
            const data = await changeRequest(alteracaoPedido)
            if (data) {
                this.showFeedback(intl.get('orders.feedback.orderSavedSuccessfully'))
                this.loadCompletePage()
            }
            else
                this.showFeedback(intl.get('stocks.timeline.feedback.noChangeOrder'))
        } catch (err) {
            this.showFeedback(formatErrorMessage(err))
            console.log(err)
        } finally {
            this.handleFetch(false)
        }
    }

    async cancelarSaldoPedidoVolume(itemVolume, indexOrigem) {
        const user = SessionUser.get()
        this.handleFetch()

        try {
            await cancelBalance(itemVolume.Id, this.state.idFamiliaRotuladaCentro, user.Id);
            this.showFeedback(intl.get('stocks.timeline.feedback.volumeCanceledSuccessfully'))
            const pedidosVolume = this.state.estoqueDetalhe.ListaPedidosVolume;
            const remainingOrdersVolum = pedidosVolume[indexOrigem].Pedidos.filter(item => item.Id !== itemVolume.Id);
            let newPedidosVolume = pedidosVolume;
            newPedidosVolume[indexOrigem].Pedidos = remainingOrdersVolum
            this.setState(prevState => ({
              estoqueDetalhe: {
                ...prevState.estoqueDetalhe,
                ListaPedidosVolume: [
                  ...newPedidosVolume
                ]
              }
            }));
        } catch (err) {
            this.showFeedback(intl.get('stocks.timeline.feedback.thereProblemDeletingVolume'))
        } finally {
            this.handleFetch(false)
        }
    }

    async searchMessages() {
        try {
            const data = JSON.parse(localStorage.getItem('enumerators'));
            this.proccessDataMessages(data.OrderCancellationJustifications)
        } catch (err) {
            this.showFeedback(formatErrorMessage(err))
        }
    }

    proccessDataMessages(data) {
        this.setState({
            justificationMessages: data
        })
    }

    async cancelarPedido(idJustificativaCancelamento) {
        if (this.state.pedido && this.state.pedido.PedidoOriginadoVolume) {
            this.closeCancelConfirm()
            this.showCompositionOrders(idJustificativaCancelamento)
        } else {
            const user = SessionUser.get()
            const idPedidoCancelamento = this.state.idPedidoCancelamento
            const cancelamento = {
                IdPedido: idPedidoCancelamento,
                IdJustificativa: idJustificativaCancelamento,
                IdUsuario: user.Id
            }

            this.handleFetch()
            try {
                await cancelOrder(this.state.idFamiliaRotuladaCentro, cancelamento)
                this.showFeedback(intl.get('stocks.timeline.feedback.orderCanceledSuccessfully'))
                this.closeCancelConfirm()
                const listaPedido = this.state.estoqueDetalhe.ListaPedidos;
                const remainingOrders = listaPedido[this.state.indexOrigem].Pedidos.filter(item => item.Id !== idPedidoCancelamento)

                let pedidos = listaPedido;
                pedidos[this.state.indexOrigem].Pedidos = remainingOrders;
                this.setState(prevState => ({
                    indexOrigem: null,
                    lastOrderOpen: {
                        index: {},
                        tableListBodyElement: {},
                    },
                    estoqueDetalhe: {
                        ...prevState.estoqueDetalhe,
                        ListaPedidos: [
                            ...pedidos
                        ]
                    }
                }));

            } catch (err) {
                this.showFeedback(formatErrorMessage(err))
            } finally {
                this.handleFetch(false);
            }
        }
    }

    showCancelConfirm = (item, indexOrigem) => {
        this.setState({
            showCancelConfirm: true,
            idPedidoCancelamento: item.Id,
            indexOrigem: indexOrigem
        })
    }

    closeCancelConfirm = () => {
        this.setState({ showCancelConfirm: false })
    }

    showCompositionOrders = (idJustificativaCancelamento) => {
        this.setState({ showCompositionOrders: true, idJustificativaCancelamento })
    }

    showFormOrder = (item) => {
        this.setState({
            showFormOrder: true,
            pedido: {
                NumeroPedido: item.Id,
                NumeroPedidoSap: item.CodigoPedidoSAP,
                NumeroItemPedidoSap: item.CodigoItemPedidoSap,
                DesabilitarDataColeta: item.IntStatus == 8 ? true : false,
                DataColeta: moment(item.Coleta).format('DD/MM/YYYY'),
                DataEntrega: moment(item.DataEntregaDe).format('DD/MM/YYYY'),
                DataCriacao: item.DataCriacao,
                PossuiComposicao: item.PossuiComposicao,
                TransitTime: item.TransitTime,
                PedidoOriginadoVolume: item.PedidoOriginadoVolume,
                Quantidade: item.Total,
                Material: item.Material.MaterialPesquisa,
                UnidadeMedida: item.UnidadeMedida,
            }
        })
    }

    closeFormOrder = () => {
        this.setState({ showFormOrder: false, })
    }

    salvarNovasDatasColetaEntrega = () => {
        this.setState({
            showFormOrder: false,
        }, () => { this.loadCompletePage() })
    }

    usuarioComPermissaoParaCancelarPedidos = (possuiPermissao) => {
        this.setState({
            possuiPermissao
        })
    }

    calcTimelineMaxHeight = () => { }

    showFeedback = (message) => {
        this.setState({
            showFeedback: true,
            messageFeedback: message,
        })
    }

    closeFeedback = () => { this.setState({ showFeedback: false }) }

    handleFetch = (isFetching = true) => {
        this.setState((prevState) => ({
            requestCount: isFetching ? prevState.requestCount + 1 : prevState.requestCount - 1,
        }))
    }

    changeValueOrder = (name, value, item, indexOrigem) => {
        const {
            estoqueDetalhe: { ListaPedidos },
        } = this.state

        const indexItem = ListaPedidos[indexOrigem].Pedidos.findIndex(x => x.Id === item.Id)

        const order = {
            ...item,
            [name]: value,
        }

        let pedidos = ListaPedidos;
        pedidos[indexOrigem].Pedidos[indexItem] = order;

        this.setState(state => ({
            estoqueDetalhe: {
                ...state.estoqueDetalhe,
                ListaPedidos: [
                    ...pedidos
                ],
            }
        }));
    }

    render() {
        return (
            <div>
                <Loading fullHeightParent isLoading={this.state.requestCount > 0} />
                {this.state.estoqueDetalhe &&
                    <React.Fragment>
                        <Orders
                            data={{ ...this.state }}
                            calcTimelineMaxHeight={this.calcTimelineMaxHeight}
                            showCancelConfirm={this.showCancelConfirm}
                            showFormOrder={this.showFormOrder}
                            changeValueOrder={this.changeValueOrder}
                            saveOrder={this.saveOrder.bind(this)}
                            usuarioComPermissaoParaCancelarPedidos={this.usuarioComPermissaoParaCancelarPedidos}
                        />
                        {this.state.estoqueDetalhe.Periodicidade == TipoPedidoTransferencia &&
                            <PendingVolum
                                data={{ ...this.state }}
                                calcTimelineMaxHeight={this.calcTimelineMaxHeight}
                                showCancelConfirm={this.showCancelConfirm}
                                showFormOrder={this.showCancelConfirm}
                                cancelarSaldoPedidoVolume={this.cancelarSaldoPedidoVolume.bind(this)}
                            />}
                    </React.Fragment>
                }
                {this.state.showFormOrder &&
                    <InformAdvanceDelayOrder
                        open
                        pedido={this.state.pedido}
                        onClose={this.closeFormOrder}
                        onSave={this.salvarNovasDatasColetaEntrega}
                    />
                }
                <SnackBar
                    message={this.state.messageFeedback}
                    open={this.state.showFeedback}
                    autoHideDuration={3000}
                    onRequestClose={this.closeFeedback}
                />

                <DangerDialog
                    show={this.state.showCancelConfirm}
                    title={intl.get('orderPlanning.modalCancellation.title')}
                    labelButton={intl.get('orderPlanning.modalCancellation.confirmationButton')}
                    labelButtonClose={intl.get('orderPlanning.modalCancellation.close')}
                    handleConfirm={this.cancelarPedido.bind(this)}
                    handleClose={this.closeCancelConfirm}
                    justificationMessages={this.state.justificationMessages}
                />
            </div>
        )
    }
}

export default OrdersVolum
