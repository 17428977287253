import React, { useState, useEffect } from 'react'
import {
  loadMaterialsWithContract, getCardInfo, editOrder,
  createSuggestion, editSuggestion, deleteSuggestions, deleteOrder, getSupplierInternalExternalByMaterial
} from '../../load-composition-building.service'
import { ObterLinhaProducaoPorCentro } from '../../../master-data/unit-management/business-unit/business-unit.service'
import {
  formatName, getItemModel, getItemModelForEdit, getItemModelForSave,
  getItemModelForUpdate, normalizeProductionLine, validateFieldsForSuggestion
} from './utils'
import Render from './render'
import intl from 'react-intl-universal'
import { OrderType } from 'models/pedidos/orderType'
import { getDoorName } from 'utils/doors'
import { getDoorsByBusinessUnity } from 'services/doors.service'
import { calculateQuantityPerTruck, calculatePalletsQuantityByTotal } from 'utils/calculations'
import { useGlobalContext } from 'hooks/useGlobalContext'
import { Incoterms } from 'models/incoterm/incoterms';

const Functions = ({ editMode, unidadeNegocio, handleChart,
  identificador, proccessError, handleClose, refreshPage, refreshCardSidebar, isSelectedCard, removeSelectedLoads, isEmergencyNegotiation }) => {
  const [incotermOptions] = useState(Incoterms.filter(x => x.Key !== 0))
  const [item, setItem] = useState(getItemModel())
  const [materials, setMaterials] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const [productionLines, setProductionLines] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [orderTypeOptions, setOrderTypeOptions] = useState({
    id: 0,
    optionOne: {
      name: intl.get('stocks.suggestionsListItem.chess'),
      value: 1,
      isChecked: true,
      hideOption: false
    },
    optionTwo: {
      name: intl.get('stocks.suggestionsListItem.transferUnits'),
      value: 2,
      isChecked: false,
      hideOption: false
    }
  })
  const [doors, setDoors] = useState([])
  const { showFeedback } = useGlobalContext()

  const init = async () => {
    const productionsLineByCenter = await ObterLinhaProducaoPorCentro(formatName(unidadeNegocio.destinoName))
    setProductionLines(normalizeProductionLine(productionsLineByCenter.ProductionLine))
    const materialsWithContract = await loadMaterialsWithContract(unidadeNegocio.destinoId)
    setMaterials(materialsWithContract)
    const doorsByCenter = await loadDoors(unidadeNegocio.destinoId)
    setDoors(doorsByCenter)

    if (editMode) {
      await loadCard(doorsByCenter)
    }
  }

  const loadDoors = async (businessUnitId) => {
    try {
      const doorsByBusinessUnity = await getDoorsByBusinessUnity(businessUnitId);
      const undeterminedOption = {
        Id: 0,
        Name: intl.get('commons.undefined')
      };

      if (doorsByBusinessUnity?.length > 0) doorsByBusinessUnity.unshift(undeterminedOption);
      const doorName = getDoorName(doorsByBusinessUnity, item.PortaWMS);
      setItem(prevState => ({
        ...prevState,
        PortaWMS: doorName
      }))

      return doorsByBusinessUnity;
    } catch (error) {
      console.error(error);
    }
  }

  const getDefaultSupplier = (orderType, suppliers) => {
    let supplier = {
      MinimumLot: '',
      TransitTime: 0
    };

    if (suppliers?.length > 0) {
      const suppliersEnabled = suppliers.filter(suppliers => suppliers.SupplierRegisterType == orderType);
      if (suppliersEnabled?.length > 0) supplier = suppliersEnabled[0];
    }

    return supplier;
  }

  const changeItem = async (prop, value) => {
    let _item = {}
    let _prop = prop

    if (_prop === 'Material') {
      const data = await getSupplierInternalExternalByMaterial(value.Id, value.MaterialNumber, unidadeNegocio.destinoId)
      setSuppliers(data)
      handleChart(value.LabeledFamilyCenterId)
      const orderTypeSelected = orderTypeOptions.optionOne.isChecked ? OrderType.PurchaseOrder : OrderType.TransferenceOrder;
      const defaultSupplier = getDefaultSupplier(orderTypeSelected, data);
      _item = {
        Fornecedor: defaultSupplier,
        IsSupplierDisable: value.Id === 0,
        QuantidadeLote: defaultSupplier.MinimumLot,
        IsOthersDisable: !defaultSupplier.SupplierCode
      }
    }

    if (_prop === 'Fornecedor') {
      _item = {
        QuantidadeLote: value.MinimumLot,
        IsOthersDisable: value.SupplierCode === ''
      }
    }

    if (_prop === 'Quantidade') {
      const quantityData = calculateQuantityPerTruck(value, item.Fornecedor.FullTruckQuantity, item.Fornecedor.Rounding)
      _item = {
        Total: quantityData.Total,
        QuantidadeCarros: quantityData.TrucksQuantity,
        Quantidade: quantityData.QuantityPallets
      }
    }

    if (_prop === 'Total') {
      const quantityData = calculatePalletsQuantityByTotal(value, item.Fornecedor.FullTruckQuantity, item.Fornecedor.Rounding)
      _item = {
        Total: quantityData.Total,
        QuantidadeCarros: quantityData.TrucksQuantity,
        Quantidade: quantityData.QuantityPallets
      }
    }

    if(_prop === 'Organization' || _prop === 'BuyerGroup' || _prop === 'TaxCode') {

      setItem(prevState => ({
        ...prevState,
        Fornecedor: {
          ...prevState.Fornecedor,
          [_prop]: value
        }
      }))
      
    } else {
      setItem(prevState => ({
        ...prevState,
        ..._item,
        [prop]: value
      }))
    }

  }

  const changeDates = (coleta, entrega) =>
    setItem(prevState => ({ ...prevState, Coleta: coleta, DataEntregaDe: entrega }))

  const loadCard = async (doors) => {
    const card = await getCardInfo(identificador)
    const doorName = getDoorName(doors, card.PortaWMS);
    const cardData = { ...card, PortaWMS: doorName }
    setItem(prevState => (getItemModelForUpdate(cardData, prevState)))
    handleChart(card.IdFamiliaRotuladaCentro)
    loadSupplier(card)
  }


  const loadSupplier = async (card) => {
    const suppliersData = await getSupplierInternalExternalByMaterial(card.IdMaterial, card.NumeroMaterial, unidadeNegocio.destinoId)
    const supplier = suppliersData.find((f) => f.Id === card.IdFornecedor && f.SupplierRegisterType === card.TipoCadastroFornecedor)

    setSuppliers(suppliersData)
    setItem(prevState => ({
      ...prevState,
      Fornecedor: {
        ...prevState.Fornecedor,
        ...supplier,
        BuyerGroup: card.GrupoCompradores,
        TaxCode: card.CodigoImposto,
        Organization: card.OrganizacaoCompras,
      }
    }))

    setOrderTypeOptions(prevState => ({
      ...prevState,
      optionOne: {
        ...prevState.optionOne,
        hideOption: identificador.name === 'IdPedido' && prevState.optionOne.value !== parseInt(supplier?.SupplierRegisterType),
        isChecked: prevState.optionOne.value === parseInt(supplier?.SupplierRegisterType)
      },
      optionTwo: {
        ...prevState.optionTwo,
        hideOption: identificador.name === 'IdPedido' && prevState.optionTwo.value !== parseInt(supplier?.SupplierRegisterType),
        isChecked: prevState.optionTwo.value === parseInt(supplier?.SupplierRegisterType)
      }
    }))
  }

  const edit = async (showSto) => {
    setIsLoading(true)
    try {
      const isSuggestion = identificador.name === 'IdSugestao'
      const model = getItemModelForEdit(item, isSuggestion)
      const validation = validateFieldsForSuggestion(model, showSto)

      if (isSuggestion && validation) {
        showFeedback(validation)
        setIsLoading(false)
        return
      }

      if(isSuggestion){
        await editSuggestion(model)
      } else if(!isEmergencyNegotiation) {
        await editOrder(model)
      }
      handleClose()
      isSelectedCard ? refreshCardSidebar(model, identificador.name) : refreshPage(0);
    }
    catch (e) {
      proccessError(e)
    }
    setIsLoading(false)
  }

  const saveSuggestion = async (showSto) => {
    setIsLoading(true)

    try {
      const model = getItemModelForSave(item)

      const validation = validateFieldsForSuggestion(model, showSto)

      if (validation) {
        showFeedback(validation)
        setIsLoading(false)
        return
      }

      await createSuggestion(model)
      handleClose();
      isSelectedCard ? refreshCardSidebar(model, "IdSugestao") : refreshPage(0);
    }
    catch (e) {
      proccessError(e)
    }
    setIsLoading(false)
  }

  const deleteSuggestion = async (id) => {
    setIsLoading(true)
    try {
      const isSuggestion = id === 'IdSugestao'
      
      isSuggestion
        ? await deleteSuggestions(item.IdFamiliaRotuladaCentro, [item.Id])
        : await deleteOrder(item.IdFamiliaRotulada, item)

      const removedId = isSuggestion ? item.Id + 'S' : item.Id + 'P'
      handleClose();
      removeSelectedLoads(removedId);
    }
    catch (e) {
      proccessError(e)
    }
    setIsLoading(false)
  }

  const updateOrderTypeOptions = (newValue) => {
    setOrderTypeOptions(prevState => ({
      optionOne: {
        ...prevState.optionOne,
        isChecked: prevState.optionOne.value === parseInt(newValue)
      },
      optionTwo: {
        ...prevState.optionTwo,
        isChecked: prevState.optionTwo.value === parseInt(newValue)
      }
    }))

    const defaultSupplier = getDefaultSupplier(newValue, suppliers);
    changeItem("Fornecedor", defaultSupplier);
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <Render
      changeItem={changeItem}
      identificador={identificador.name}
      editMode={editMode}
      item={item}
      materials={materials}
      suppliers={suppliers}
      productionLines={productionLines}
      deleteSuggestion={deleteSuggestion}
      handleClose={handleClose}
      saveSuggestion={saveSuggestion}
      isLoading={isLoading}
      incotermOptions={incotermOptions}
      changeDates={changeDates}
      edit={edit}
      orderTypeOptions={orderTypeOptions}
      updateOrderTypeOptions={updateOrderTypeOptions}
      doors={doors}
    />
  )
}

export default Functions
